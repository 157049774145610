jQuery(document).ready(function () {

    initCommentsForm();
    initClickEvents();
});

function initFormValidating($form){
    $form.validator().on('submit', function (e) {
        if (e.isDefaultPrevented()) {
            // handle the invalid form...
        } else {
            // everything looks good!
            sendComment($(this), e);
            e.preventDefault();
        }
    });
}

function initCommentsForm(){
    var $commentsForm = $("#zp-comment-form"),
        $commentsAddLink = $(".js-comments-add");

    initFormValidating($commentsForm);

    $commentsAddLink.on("click", function (e) {
        $commentsForm.fadeToggle(300);
    });
}

function fillCommentsEditForm($form, $commentWrapper){
    $form.find("[name=comment_id]").val($commentWrapper.attr("id"));
    $form.find("[name=user_name]").val($commentWrapper.find(".comment_data_user").text());
    $form.find("[name=email]").val($commentWrapper.find(".comment_data_email").text());
    $form.find("[name=content]").val($commentWrapper.find(".comment_data_content").text());
    $form.find("[name=comment_type]").val($commentWrapper.data("type"));
    $form.find("[name=comment_flag]").val($commentWrapper.data("flag"));
}
function initClickEvents() {

    $(".js-edit-link").click(function () {
        var $this = $(this),
            $editForm = $('#zp-comment-edit-form');
        clearFields($editForm);
        fillCommentsEditForm($editForm, $this.parents('tr'));
        initFormValidating($editForm);
    });

    $(".js-answer-link").click(function () {
        var $this = $(this),
            $answerForm = $('#zp-answer-form');

        $this.siblings(".js-answer-form").html($answerForm);
        $answerForm.find("[name=parent_comment]").val($this.data("comment"));

        if ($this.attr("has_form") == "has_form") {
            $this.removeAttr("has_form");
            $answerForm.hide();
        } else {
            $(".js-answer-link").each(function () {
                $this.removeAttr("has_form");
            });
            $this.attr("has_form", "has_form");
            $answerForm.show();
        }
        initFormValidating($answerForm);
    });

    $(".comments__list .js-action-link").click(function () {
        var $this = $(this),
            action = $this.data("action");

        if (action == "delete") {
            if (confirm("Вы действительно хотите удалить?")) {
                adminComment($this);
            }
        } else {
            adminComment($this);
        }
    });
}

function sendComment(commentsForm, event) {
    $.ajax({
        type: 'POST',
        url: '/system/modules/ru.zen_it.portal.content.comments/pages/comment_result.jsp',
        data: commentsForm.serialize(),
        success: function (result) {
            var jsonResult = JSON.parse(result);
            showSendResult(commentsForm.find('.js-comments-result'), jsonResult.result == 'ok', jsonResult.message);
            // commentsForm.find('.js-comments-result').html(jsonResult.message);
            refreshCaptcha($(commentsForm).find('.js-captcha-img'));
            if (jsonResult.result == 'ok') {
                clearFields(commentsForm);
            } else if (jsonResult.result == 'error-captcha') {
                commentsForm.find('input[name=captcha_phrase]').addClass('error');
            }
            // TODO обновление коммента через JS
            if (jsonResult.reload) {
                setTimeout(function () {
                    location.reload();
                }, 1000);
            }
        }
    });
}

function showSendResult(elementToshow, success, message){
    var msgHtml = '<div class="alert alert-'+(success ? 'success' : 'danger')+ '">' +
        '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button><strong>' +
        message + '</strong></div>';
    elementToshow.html(msgHtml);
}

function refreshCaptcha(captchaField) {
    $(captchaField).attr("src", "/system/modules/ru.zen_it.portal.content.comments/pages/captcha.jsp?" + new Date().getTime());
}

function clearFields(form) {
    $(form).find("input[type=text], textarea, select").each(function () {
        $(this).val('');
    });
    setTimeout(function () {
        $('.js-comments-result').html('');
    }, 4000);
}

function adminComment(aObject) {
    var action = aObject.data("action"),
        comment_id = aObject.data("comment"),
        commentResource = aObject.parents('.js-action-bar').data("resource");

    console.log("adminComment", action, comment_id);
    $.ajax({
        type: 'POST',
        url: '/system/modules/ru.zen_it.portal.content.comments/pages/comment_result.jsp',
        data: {comment_action: action, comment_id: comment_id, comment_cms_resource: commentResource},
        success: function (result) {
            var jsonResult = JSON.parse(result);
            doAction(action, comment_id, jsonResult.result);
        }
    });
}

function setAnswerText(comment, hide) {
    var $answerLink = $(".js-answer-link[data-comment=" + comment + "]");
    if (hide) {
        $answerLink.fadeOut(500);
    } else {
        $answerLink.fadeIn(200);
    }
}

function showMessage(aElement, message, type) {
    var $messageDiv = $(aElement).closest(".js-action-bar").find(".js-action-result");

    $messageDiv.html('<small class="text-' + type + '">' + message + '</small>');
    setTimeout(function () {
        $messageDiv.html("");
    }, 3000);
}

function doAction(action, comment, result) {
    var $aElement = $(".js-action-link[data-action=" + action + "][data-comment=" + comment + "]");

    if (result == "ok") {
        if (action == "approve") {
            $aElement.hide();
            setAnswerText(comment, false);
            showMessage($aElement, "Элемент успешно подтвержден", "success");
        } else if (action == "block") {
            var $blockSpan = $aElement,
                $unblockSpan = $(".js-action-link[data-action='unblock'][data-comment=" + comment + "]");

            showMessage($aElement, "Элемент успешно заблокирован", "success");
            $unblockSpan.show();
            $blockSpan.hide();
            setAnswerText(comment, true);

            var $answerForm = $('#zp-answer-form'),
                $answerLink = $(".js-answer-link[data-comment=" + comment + "]");

            if ($answerLink.attr("has_form") == "has_form") {
                $answerLink.removeAttr("has_form");
                $answerForm.hide();
            }
        } else if (action == "unblock") {
            var $unblockSpan = $aElement,
                $blockSpan = $(".js-action-link[data-action='block'][data-comment=" + comment + "]");

            showMessage($aElement, "Элемент успешно разблокирован", "success");
            $blockSpan.show();
            $unblockSpan.hide();
            setAnswerText(comment, false);
        } else if (action == "delete") {
            $aElement.closest(".js-comments-item").hide();
        }
    } else {
        if (action == "approve") {
            showMessage($aElement, "Ошибка подтверждения", "danger");
        } else if (action == "block") {
            showMessage($aElement, "Ошибка блокирования", "danger");
        } else if (action == "unblock") {
            showMessage($aElement, "Ошибка разблокирования", "danger");
        } else if (action == "delete") {
            showMessage($aElement, "Ошибка удаления", "danger");
        }
    }
}
function setCommentsFormAdminAction(action) {
    $('#comments-admin-form').find('input[name=comment_action]').val(action);
}