Highcharts.setOptions({
    lang: {
        contextButtonTitle: "Меню графика",
        downloadJPEG: "Скачать JPEG",
        downloadPDF: "Скачать PDF",
        downloadPNG: "Скачать PNG",
        downloadSVG: "Скачать SVG",
        drillUpText: "< назад к \"{series.name}\"",
        loading: "Загрузка...",
        months: [ "Январь" , "Февраль" , "Март" , "Апрель" , "Май" , "Июнь" , "Июль" , "Август" , "Сентябрь" , "Октябрь" , "Ноябрь" , "Декабрь"],
        noData: "Нет данных для отображения",
        numericSymbolMagnitude: 1000,
        numericSymbols: [ "тыс." , "млн" , "млрд" , "трлн" , "P" , "E"],
        printChart: "Распечатать",
        resetZoom: "Сбросить масштаб",
        resetZoomTitle: "Сбросить масштабирование 1:1",
        shortMonths: [ "Янв" , "Фев" , "Мар" , "Апр" , "Май" , "Июн" , "Июл" , "Авг" , "Сен" , "Окт" , "Ноя" , "Дек"],
        weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Черверг", "Пятница", "Суббота"]
    }
});