// TODO функции инициализации для портала

$.fn.datepicker.defaults.format = "dd.mm.yyyy";
$.fn.datepicker.defaults.language = "ru";

Array.prototype.contains = function (needle) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == needle) return true;
    }
    return false;
};


var BudgetTable = function () {

    function init() {
        var $table = $(".zp-budget-table table"),
            $tr = $table.find("tr.collapsed, tr.expanded");

        $tr.on("click", function (e) {
            var $this = $(this),
                id = $this.data("id"),
                lvl = $this.data("level"),
                childLevel = lvl + 1,
                rowCss = $this.data("css"),
                $child;
            // $child = $table.find("tr[data-parent=" + id + "]");

            //фикс чтобы не скакали колонки
            var $tdName = $this.find("td[class*='data-lvl']")
            $tdName.css("width", $tdName.outerWidth(true));

            if ($this.hasClass("collapsed")) {
                $child = $table.find("tr." + rowCss).filter('[data-level=' + childLevel + ']');
                $child.show();
                $this.addClass("expanded");
                $this.removeClass("collapsed");
            } else {
                $child = $table.find("tr." + rowCss);
                $child.hide();
                $this.addClass("collapsed").removeClass("expanded");
                $table.find("tr." + rowCss).filter('.expanded').addClass('collapsed').removeClass('expanded');
            }
        });
    }


    return {
        init: init
    }
}(jQuery);

BudgetTable.init();

