jQuery(document).ready(function () {
   /*
    $('form.poll_form').validate({
        focusInvalid: false,
        onfocusout: false,
        errorClass: "error",
        errorPlacement: function ($error, $element) {
            addError($element);
        }
    });
    */
    var $pollForm = $('form.js-poll-form');
    $pollForm.validator();

    $($pollForm).find('.form-group').on("click",'input:checkbox',function(){
        checkboxValidate($(this).attr('name'));
    });
    function checkboxValidate(name){
        var min = 1; //minumum number of boxes to be checked for this form-group
        if($('input[name="'+name+'"]:checked').length<min){
            $('input[name="'+name+'"]').prop('required',true);
        }
        else{
            $('input[name="'+name+'"]').prop('required',false);
            $pollForm.validator('update')
        }
    }

});

function removeErrors(pollForm) {
    $(pollForm).find('.fld-msg').remove();
}
function addError(element) {
    $(element).parents("form").find('.js-poll-msg').html("<div class='js-poll-msg alert alert-danger'>Необходимо ответить на все вопросы</div>")
}